<template>
  <div class="IncidentHeader">
    <template v-if="!editing">
      <div class="title">{{ title }}</div>
      <div class="toolbar flex-shrink-0">
        <base-button color="danger"
                     size="small"
                     :outlined="true"
                     :loading="deleting"
                     @click="onIncidentDelete">Delete Incident</base-button>
        <base-button v-if="!editing"
                     size="small"
                     :outlined="true"
                     @click="onIncidentUpdate">Edit Title</base-button>
      </div>
    </template>
    <template v-else>
      <ValidationObserver ref="observer" slim>
        <form class="d-flex justify-content-between"
              style="width: 100%"
              @submit.prevent="submit">
          <base-textbox ref="title"
                        class="flex-grow-1"
                        placeholder="Incident title"
                        v-model="title" />

          <div class="toolbar ml-2">
            <base-button :outlined="true"
                         color="danger"
                         @click="onCancel">Cancel</base-button>
            <base-button type="submit">Save</base-button>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import incidentsApi from '@/api/incidentsApi.js'
import swal from 'sweetalert2'

export default {
  props: {
    incident: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      title: this.incident.title,
      editing: false,
      deleting: false
    }
  },

  methods: {
    async submit () {
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        return false
      }

      this.editing = false
      await incidentsApi.update(this.incident.id, { title: this.title })

      this.$emit('submitSuccess')
    },

    onIncidentUpdate () {
      this.editing = true
      this.$nextTick(() => {
        this.$refs.title.focus()
      })
    },

    async onIncidentDelete () {
      swal.fire({
        html: '<h4>' + 'Are you sure that you want to permanently delete "' + this.title + '" incident?</h4>',
        title: '',
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        showCancelButton: true,
        confirmButtonColor: '#eb5757',
        cancelButtonColor: '#9ca3a1',
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel'
      }).then(val => {
        if (val.value === true) {
          this.deleting = true
          incidentsApi.delete(this.incident.id)
          this.deleting = false

          this.$router.push({
            name: 'statusPages.single.incidents',
            params: {
              id: this.incident.status_page_id
            }
          })
        }
      })
    },

    onCancel () {
      this.title = this.incident.title
      this.editing = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .IncidentHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;

    .title {
      font-size: 20px;
    }

    .base-text-box {
      margin: 0;
    }
  }
</style>
