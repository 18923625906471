<template>
  <div class="IncidentUpdate">
    <ValidationObserver ref="observer">
      <form @submit.prevent="submit">
        <base-select-tag label="Status"
                         v-model="selectedUpdateStatus"
                         :options="updateStatuses" />
        <div class="row">
          <base-date-picker
            placeholder="Date"
            v-model="update.date"
            :config="datePickerConfig"
            label="Date"
            class="col col-6 date-picker"
            description="If empty, the current date will be used"
          />
        </div>

        <base-textarea label="Message"
                       ref="message"
                       rows="5"
                       v-model="update.message"
                       rules="required"
                       :placeholder="messagePlaceholder" />

        <div class="d-flex justify-content-end">
          <base-button type="submit"
                       :loading="saving">Publish</base-button>
        </div>
      </form>
    </ValidationObserver>
    <base-alert type="error" v-if="savingError" class="mt-3">
      Something went wrong
    </base-alert>
  </div>
</template>

<script>
import incidentsApi from '@/api/incidentsApi.js'

export default {
  props: {
    incident: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      update: {
        incident_id: this.incident.id,
        status: null,
        date: '',
        message: ''
      },

      datePickerConfig: {
        enableTime: true,
        altFormat: 'd/m/Y h:i K',
        altInput: true
      },

      updateStatuses: [
        {
          value: 'exploring',
          label: 'Exploring'
        },
        {
          value: 'identified',
          label: 'Identified'
        },
        {
          value: 'update',
          label: 'Update'
        },
        {
          value: 'resolved',
          label: 'Resolved'
        }
      ],
      selectedUpdateStatus: null,

      saving: false,
      savingError: false,
      tippy: null
    }
  },

  created () {
    this.refreshIncidentUpdate()
  },

  mounted () {
    // Commented to fix edit incident button bug
    // this.$refs.message.focus()
  },

  methods: {
    async submit () {
      this.savingError = false
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        return false
      }

      this.saving = true

      try {
        await incidentsApi.storeUpdate(this.incident.id, this.update)
        this.refreshIncidentUpdate()
        this.$emit('submitSuccess')
      } catch (e) {
        this.savingError = true
      }
      this.saving = false
    },

    refreshIncidentUpdate () {
      this.update = {
        incident_id: this.incident.id,
        status: null,
        message: '',
        date: ''
      }

      this.selectedUpdateStatus = this.updateStatuses[0]

      if (this.$refs.observer) {
        this.$refs.observer.reset()
      }
    }
  },

  computed: {
    messagePlaceholder () {
      switch (this.selectedUpdateStatus.value) {
        case 'exploring': return 'We are currently investigating the incident'
        case 'identified': return 'We have identified the problem'
        case 'update': return 'The issue will be fixed soon'
        case 'resolved': return 'We have resolved the issue'
      }

      return null
    }
  },

  watch: {
    selectedUpdateStatus: {
      handler: function (status) {
        this.update.status = status.value
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .IncidentUpdate {
    padding-bottom: 20px;
    border-bottom: 1px solid map-get($colors, gray-3);

    .date-picker::v-deep {
      .form-control {
        padding: 21px 10px 21px 42px;
        box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);
        border: 1px solid #e9ebec;
        border-radius: 6px;
        text-align: left;
      }

      &:before {
        content: '';
        position: absolute;
        top: 45px;
        left: 30px;
        transform: translateY(-50%);
        min-width: 20px;
        height: 20px;
        background: url('../../../../assets/images/calendar.svg') no-repeat;
        pointer-events: none;
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        top: 40px;
        right: 25px;
        min-width: 11px;
        height: 8px;
        background: url('../../../../assets/images/arrow_down_small.svg') no-repeat;
        pointer-events: none;
      }

      .label {
        display: block;
        margin-bottom: 10px;
        opacity: .8;
        font-weight: 600;
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        font-feature-settings: "ss04" off;
      }
    }
  }
</style>
