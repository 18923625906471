const baseURL = 'incidents'

export default {
  /**
   * Get incidents.
   *
   * @param statusPageId
   * @param params
   * @returns Promise
   */
  async get (statusPageId, params = {}) {
    const response = await axios.get(`/status-pages/${statusPageId}/incidents`, {
      params
    })

    return response.data
  },

  /**
   * Get specific incident by id.
   *
   * @param id
   * @param params
   * @returns Promise
   */
  async find (id, params = {}) {
    const response = await axios.get(`${baseURL}/${id}`)

    return response.data.data
  },

  /**
   * Store new incident.
   *
   * @param statusPageId
   * @param params
   * @returns Promise
   */
  async store (statusPageId, params) {
    const response = await axios.post(`/status-pages/${statusPageId}/incidents`, params)

    return response.data
  },

  /**
   * Update incident.
   *
   * @param id
   * @param params
   * @returns Promise
   */
  async update (id, params) {
    const response = await axios.put(`/incidents/${id}`, params)

    return response.data
  },

  /**
   * Store new incident update.
   *
   * @param id
   * @param params
   * @returns Promise
   */
  async storeUpdate (id, params) {
    const response = await axios.post(`/incidents/${id}/update`, params)

    return response.data
  },

  /**
   * Update incident update.
   *
   * @param id
   * @param params
   * @returns Promise
   */
  async updateUpdate (id, params) {
    const response = await axios.put(`/incidents/updates/${id}`, params)

    return response.data
  },

  /**
   * Delete incident.
   *
   * @param id
   * @returns Promise
   */
  async deleteUpdate (id) {
    return axios.delete(`/incidents/updates/${id}`)
  },

  /**
   * Delete incident.
   *
   * @param id
   * @returns Promise
   */
  async delete (id) {
    return axios.delete(`${baseURL}/${id}`)
  }
}
