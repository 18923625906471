<template>
  <div class="IncidentUpdateEdit">
    <ValidationObserver ref="observer">
      <form @submit.prevent="submit">
        <div class="d-flex justify-content-between align-items-start">
          <base-select-tag label="Status"
                           v-model="selectedUpdateStatus"
                           :options="incidentUpdateStatuses" />
        </div>

        <div class="row">
          <base-date-picker
            v-model="update.date"
            :config="datePickerConfig"
            label="Date"
            class="col col-6 date-picker"
          />
        </div>

        <base-textarea label="Message"
                       ref="message"
                       rows="5"
                       v-model="updateCopy.message"
                       rules="required" />

        <div class="d-flex justify-content-between align-items-center">
          <base-button color="danger"
                       :loading="deleting"
                       @click="onDeleteClick">Delete</base-button>
          <div class="toolbar">
            <base-button color="light"
                         @click="$emit('close')">Cancel</base-button>
            <base-button type="submit"
                         :loading="saving">Save</base-button>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <base-alert type="error" v-if="error" class="mt-3">
      Something went wrong
    </base-alert>
  </div>
</template>

<script>
import incidentsApi from '@/api/incidentsApi.js'
import swal from 'sweetalert2'

export default {
  props: {
    update: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      updateCopy: null,
      selectedUpdateStatus: null,
      saving: false,
      deleting: false,
      error: false,

      datePickerConfig: {
        enableTime: true,
        altFormat: 'd/m/Y h:i K',
        altInput: true
      }
    }
  },

  created () {
    this.updateCopy = { ...this.update }
    this.selectedUpdateStatus = this.incidentUpdateStatuses.find(status => status.value === this.update.status)
  },

  methods: {
    async submit () {
      this.error = false
      const valid = await this.$refs.observer.validate()

      if (!valid) {
        return false
      }

      this.saving = true
      try {
        await incidentsApi.updateUpdate(this.update.id, this.updateCopy)
        this.$emit('edit')
      } catch (e) {
        this.error = true
      }
      this.saving = false
    },

    async onDeleteClick () {
      swal.fire({
        html: '<h4>' + 'Are you sure that you want to permanently delete "' + this.updateCopy.message + '" update?</h4>',
        title: '',
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        showCancelButton: true,
        confirmButtonColor: '#eb5757',
        cancelButtonColor: '#9ca3a1',
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel'
      }).then(val => {
        if (val.value === true) {
          this.error = false
          this.deleting = true
          try {
            incidentsApi.deleteUpdate(this.update.id)
            this.$emit('delete')
          } catch (e) {
            this.error = true
          }
          this.deleting = false
        }
      })
    }
  },

  computed: {
    ...mapState('system', [
      'incidentUpdateStatuses'
    ])
  },

  watch: {
    selectedUpdateStatus: {
      handler: function (status) {
        this.updateCopy.status = status.value
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.date-picker::v-deep {
  .form-control {
    padding: 21px 10px 21px 42px;
    box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);
    border: 1px solid #e9ebec;
    border-radius: 6px;
    text-align: left;
  }

  &:before {
    content: '';
    position: absolute;
    top: 45px;
    left: 30px;
    transform: translateY(-50%);
    min-width: 20px;
    height: 20px;
    background: url('../../../../../assets/images/calendar.svg') no-repeat;
    pointer-events: none;
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 40px;
    right: 25px;
    min-width: 11px;
    height: 8px;
    background: url('../../../../../assets/images/arrow_down_small.svg') no-repeat;
    pointer-events: none;
  }

  .label {
    display: block;
    margin-bottom: 10px;
    opacity: .8;
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-feature-settings: "ss04" off;
  }
}
</style>
