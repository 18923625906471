<template>
  <div class="card">
    <div class="card-body">
      <div>
        <div v-if="incident">
          <div class="row">
            <div class="col-md-8">
              <incident-header :incident="incident" />

              <incident-update-new class="mb-4"
                                   :incident="incident"
                                   @submitSuccess="loadIncident" />

              <incident-update v-for="update in incident.updates"
                               :key="update.id"
                               :update="update"
                               class="incident-update"
                               @update="loadIncident"
                               @delete="loadIncident" />
            </div>
          </div>
        </div>
        <div id="custom-styles"></div>
      </div>
    </div>
  </div>
</template>

<script>
import IncidentHeader from '@/components/StatusPage/Settings/Incidents/IncidentHeader.vue'
import IncidentUpdate from '@/components/StatusPage/Settings/Incidents/IncidentUpdate/IncidentUpdate.vue'
import IncidentUpdateNew from '@/components/StatusPage/Settings/Incidents/IncidentUpdateNew.vue'
import incidentsApi from '@/api/incidentsApi.js'

export default {
  components: {
    IncidentHeader,
    IncidentUpdate,
    IncidentUpdateNew
  },

  props: {
    statusPage: {
      required: true,
      type: Object
    },

    incidentId: {
      required: true
    }
  },

  data () {
    return {
      incident: null
    }
  },

  mounted () {
    this.setStyles()
  },

  created () {
    this.loadIncident()
  },

  methods: {
    async loadIncident () {
      this.incident = await incidentsApi.find(this.incidentId)
      this.incident.updates = this.incident.updates.map(item => {
        item.title = this.incident.title
        return item
      })
    },

    // styles for status page settings
    // async setStyles () {
    //   let customStyles = document.getElementById('custom-styles')
    //   let styles = {}
    //   if (this.statusPage.status_pages_style) {
    //     for (let key in this.statusPage.status_pages_style) {
    //       if (
    //         (typeof this.statusPage.status_pages_style[key] === 'string' && this.statusPage.status_pages_style[key].includes('{"')) ||
    //         key === 'logo' || key === 'header_image' || key === 'custom_css'
    //       ) {
    //         styles[key] = JSON.parse(this.statusPage.status_pages_style[key])
    //       } else {
    //         styles[key] = this.statusPage.status_pages_style[key]
    //       }
    //     }
    //   }
    //   if (Object.keys(styles).length) {
    //     let familyResponse = await fetch(`https://fonts.googleapis.com/css?family=${styles.text_style.font.family.split(' ').join('+')}`)
    //     let fontStyles = await familyResponse.text()
    //     let fontStylesElement = document.createElement('style')
    //     fontStylesElement.innerHTML = fontStyles
    //     document.body.appendChild(fontStylesElement)
    //     customStyles.innerHTML = `
    //     <style>
    //       .IncidentUpdateView {
    //         color: #${styles.text_style.color.color} !important;
    //         font-family: ${styles.text_style.font.value}, serif;
    //       }
    //       .IncidentUpdateView .IncidentStatus {
    //         border-radius: ${styles.incidents_state_style.roundness.value}px !important;
    //       }
    //       .IncidentUpdateView .IncidentStatus.resolved {
    //         background-color: #${styles.incidents_state_style.resolved.color} !important;
    //       }
    //       .IncidentUpdateView .IncidentStatus.update {
    //         background-color: #${styles.incidents_state_style.update.color} !important;
    //       }
    //       .IncidentUpdateView .IncidentStatus.exploring {
    //         background-color: #${styles.incidents_state_style.exploring.color} !important;
    //       }
    //       .IncidentUpdateView .IncidentStatus.identified {
    //         background-color: #${styles.incidents_state_style.identified.color} !important;
    //       }
    //       .IncidentUpdateView .posted-at {
    //         color: #${styles.text_style.color.color} !important;
    //         opacity: 0.5 !important;
    //       }
    //       .IncidentUpdateView .incident-history {
    //         color: #${styles.text_style.color.color} !important;
    //       }
    //       ${styles.custom_css}
    //     </style>
    //   `
    //   }
    // },

    updateTitle () {

    }
  }
}
</script>

<style lang="scss" scoped>
.incident-update {
  margin-bottom: 30px;
}
</style>
