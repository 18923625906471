<template>
  <div class="IncidentUpdateView">
    <div class="header">
      <incident-update :update="update" :title="update.title"/>
      <base-button class="edit"
                   :outlined="true"
                   size="small"
                   @click="$emit('edit')">Edit</base-button>
    </div>
  </div>
</template>

<script>
import IncidentUpdate from '@/components/StatusPage/Incidents/IncidentsItemUpdate.vue'

export default {
  components: {
    IncidentUpdate
  },

  props: {
    update: {
      required: true,
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
  .IncidentUpdateView {
    position: relative;
    .header {
      display: flex;
      justify-content: space-between;

      .edit {
        display: none;
      }
    }

    &:hover .header .edit {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
    }

    .message {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 1.5;
    }

    .posted-at {
      font-size: 12px;
      color: map-get($colors, gray-2);
    }
  }
</style>
