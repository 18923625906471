<template>
  <div class="IncidentUpdate">
    <incident-update-view
      :update="update"
      @edit="editing = true"
      v-if="!editing"
    />

    <incident-update-edit
      :update="update"
      @edit="onUpdate"
      @delete="$emit('delete')"
      @close="editing = false"
      v-else
    />
  </div>
</template>

<script>
import IncidentUpdateView from './IncidentUpdateView.vue'
import IncidentUpdateEdit from './IncidentUpdateEdit.vue'

export default {
  components: {
    IncidentUpdateView,
    IncidentUpdateEdit
  },

  props: {
    update: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      editing: false,
      selectedUpdateStatus: null
    }
  },

  methods: {
    onUpdate () {
      this.editing = false
      this.$emit('update')
    },

    onCancel () {
      this.editing = false
    }
  },

  computed: {
    ...mapState('system', [
      'incidentUpdateStatuses'
    ])
  }
}
</script>
